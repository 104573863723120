import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahPresentsImage from '../../assets/images/publication-banners/utah-presents.jpg';

const UtahPresents = (props) => (
  <Layout>
    <Helmet>
      <title>UtahPresents - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for UtahPresents’ season playbills. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>UtahPresents</h1>
          </header>
          <span className="image main">
            <img src={UtahPresentsImage} alt="" />
          </span>
          <p className="box">
            For the curious who want to experience artistic creativity pushed
            beyond the stage, UtahPresents ignites dialogue, explores issues and
            ideas, catalyzes innovation, and connects us. Creativity Connected.
            <br />
            <br />
            UtahPresents, a multi-disciplinary presenter at the University of
            Utah, brings diverse artistic and cultural experiences to campus and
            the region, exploring and enriching the human experience through the
            lens of creativity and the arts.
            <br />
            <br />
            A limited opportunity is available within the Utah Presents /
            Odyssey Dance Theatre programs that will save advertisers thousands
            of dollars by pre-printing their ad. Please note that ad copy must
            run static from September 2019 through April 2020, and is due at the
            beginning of the season as indicated.
            <br />
            <br />
            Mills Publishing is pleased to include Odyssey Dance Theatre in our
            selection of playbills at Kingsbury Hall.
          </p>
          <ul className="actions">
            <li>
              <a href="https://utahpresents.org/" className="button special">
                <i className="icon fa-globe"> </i>UtahPresents Website
              </a>
            </li>
          </ul>
          <h2>UtahPresents {new Date().getFullYear()} Season Information</h2>
          <p>
            <em>
              Dates are subject to change. Please contact us if you have any
              questions.
            </em>
          </p>
          <div className="grid-wrapper">
            <div className="col-6 box">
              <h3>TBD</h3>
              <p>
                <strong>TBD</strong> <br />
                TBD
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: TBD <br />
                  Circulation: TBD
                </section>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahPresents;
